const enums = {
  colorTheme: {
    primary: "#1d9bc2", // blue

    secondary: "#075985", // hard blue
    secondaryLight: "#EFF6FF",

    teritiary: "#bd8b1e", //gold
    fouraryLight: "#5e36e0", // purple
    // we just have to make this, red: {primary, secondary, teritiary}, blue: {p,s,t}, purple: {p,s,t}.. the secret behind primary, secondary scheme for color themes in a software...
  },

  orderStatus: {
    inReview: "In review",
    accepted: "Accepted",
    making: "Making",
    dispatched: "Dispatched",
    delivered: "Delivered",
    returned: "Returned",
    canceled: "Canceled",
  },

  stockStatus: {
    staged: "Staged",
    inventory: "In Inventory",
    approval: "In Approval",
    issued: "Issued",
  },

  "vendor-portal": {
    orderStatus: {
      pending: "Pending",
      assigned: "Assigned",
      accepted: "Accepted",
      inProgress: "In Progress",
      delivered: "Delivered",
      resent: "Resent",
      canceled: "Canceled",
    },

    // Clone to e-creatives project as well
    pdfTemplates: {
      template1: "Template-1",
      template2: "Template-2",
      template3: "Template-3",
    },
  },

  keywords: {
    selectAll: "Select All",
    includedUsers: {
      public: "All Unregistered Users",
      private: "All Registered Users",
    },
    dataEngine: "Data-Engine",
  },

  workflow: {
    wholesale: "Wholesale",
    productWise: "Product Wise",
  },

  roles: {
    superAdmin: "Super Admin",
    admin: "Admin",
    manager: "Manager",
    branch: "Branch",
    zoneManager: "Zone Manager",
    inventoryManager: "Inventory Manager",
    salesHead: "Sales Head",
    salesPerson: "Sales Person",
    vendor: "Vendor",
    customer: "Customer",
  },

  fieldTypes: {
    textField: "textField",
    dropdown: "dropdown",
    asyncDropdown: "asyncDropdown",
    upload: "upload",
  },
  dataTypes: {
    string: "string",
    number: "number",
    object: "object",
    array: "array",
  },

  models: {
    iam: {
      users: "iam/users",
      clients: "iam/clients",
      access: "iam/access",

      countries: "iam/countries",
      states: "iam/states",
      cities: "iam/cities",
    },
    "vendor-portal": {
      quotations: "vendor-portal/quotations",
      users: "vendor-portal/users",
      dumps: "vendor-portal/dumps",
      configurations: "vendor-portal/configurations",
      settings: "vendor-portal/settings",
      vendorCodes: "vendor-portal/vendorCodes",
    },
    "e-creatives": {
      orders: "e-creatives/orders",

      stockOrders: "e-creatives/stockOrders", // for Stock Orders screen in OP and VP
      customOrders: "e-creatives/customOrders", // OP VP

      catalogueQuotations: "e-creatives/catalogueQuotations",
      exhibitionQuotations: "e-creatives/exhibitionQuotations",

      linkItCarts: "e-creatives/linkItCarts",
      linkItOrders: "e-creatives/linkItOrders",

      expiryLinks: "e-creatives/expiryLinks",
      catalogues: "e-creatives/catalogues",
    },
    "data-engine": {
      additionalMedia: "data-engine/additionalMedia",
      products: "data-engine/products",
      purity: "data-engine/purity",
      collectionLines: "data-engine/collectionLines",
      category: "data-engine/category",
      subCategory: "data-engine/subCategory",
      users: "data-engine/users", // All Customers from all apps will be here
      configuration: "data-engine/configuration",
      printerTemplates: "data-engine/printerTemplates",

      diamondPricing: "data-engine/diamondPricing",
      goldPricing: "data-engine/goldPricing",
      extrasPricing: "data-engine/extrasPricing",
    },
    divisions: "data-engine/divisions",
    products: "data-engine/products",
    dashboard: "vendor-portal/dashboard",
    masterData: "vendor-portal/masterData", // not a model, just for route
    quotations: "vendor-portal/quotations",
    users: "vendor-portal/users",
    dumps: "vendor-portal/dumps",
    configurations: "vendor-portal/configurations",
    settings: "vendor-portal/settings",
    vendorCodes: "vendor-portal/vendorCodes",

    dataEntry: "data-engine/dataEntry", // not a model, just for route
    bulkPrint: "data-engine/bulkPrint", // not a model, just for route
    excelImport: "data-engine/excelImport", // not a model, just for route
    imageUpload: "data-engine/imageUpload", // not a model, just for route
  },

  dataSource: {
    orderPro: "Order Pro",
    orderProMobile: "Order Pro Mobile",
    orderProAdminMobile: "Order Pro Admin Mobile",
  },

  customizationType: {
    product: "product",
    scratch: "scratch",
  },

  ordersType: {
    stockOrder: "Stock Order", // orders placed by Employees in OP Admin mobile App
    customerOrder: "Customer Order", // orders placed by Customers in OP mobile app
  },

  orderPriority: {
    ordered: "Ordered",
    makeToOrder: "Make To Order",
  },

  customOrderPriority: { normal: "Normal", urgent: "Urgent" },
  customOrderType: {
    custom: "Custom",
    online: "Online",
  },

  productsImportKey: {
    // DONT CHANGE THE ORDER OF ANY enum
    sku: "SKU",
    styleNo: "Style No",
    category: "Category",
    subCategory: "Sub Category",
    collectionLine: "Collection Line",
    purity: "Purity",
    color: "Color",
    grossWeight: "Gross Weight",
    netWeight: "Net Weight",
    lessWeight: "Less Weight",
    stockStatus: "Stock Status",
  },

  stockLevelField: {
    // IF YOU ADD NEW WEIGHT FIELDS, give it for lessWeight useEffect calculation dependancy

    vendorCode: "Vendor Code",
    zone: "Zone",
    qty: "Pieces",
    size: "Size",
    actualGrossWeight: "Actual Gross Weight",
    specificEngraving: "Specific Engraving",
    setting: "Setting",
    screw: "Screw",

    customField: "Custom Field",
    customField1: "Custom Field 1",
    customField2: "Custom Field 2",
    customField3: "Custom Field 3",
    customField4: "Custom Field 4",

    customDiamondSize: "Custom Diamond Size",
    customDiamondWeight: "Custom Diamond Weight",
    customDiamondPieces: "Custom Diamond Pieces",
    customDiamondRate: "Custom Diamond Rate",

    stoneWeight: "Stone Weight",
    stonePieces: "Stone Pieces",
    stoneRate: "Stone Rate",

    colorStoneWeight: "Color Stone Weight",
    colorStonePieces: "Color Stone Pieces",
    colorStoneRate: "Color Stone Rate",

    chainWeight: "Chain Weight",
    chainPieces: "Chain Pieces",
    chainRate: "Chain Rate",

    motiWeight: "Moti Weight",
    motiPieces: "Moti Pieces",
    motiRate: "Moti Rate",

    moti2Weight: "Moti 2 Weight",
    moti2Pieces: "Moti 2 Pieces",
    moti2Rate: "Moti 2 Rate",

    moti3Weight: "Moti 3 Weight",
    moti3Pieces: "Moti 3 Pieces",
    moti3Rate: "Moti 3 Rate",

    kundunWeight: "Kundun Weight",
    kundunPieces: "Kundun Pieces",
    kundunRate: "Kundun Rate",

    meenaWeight: "Meena Weight",
    meenaPieces: "Meena Pieces",
    meenaRate: "Meena Rate",

    pinWeight: "Pin Weight",
    pinPieces: "Pin Pieces",
    pinRate: "Pin Rate",

    beltWeight: "Belt Weight",
    beltPieces: "Belt Pieces",
    beltRate: "Belt Rate",

    moissaniteWeight: "Moissanite Weight",
    moissanitePieces: "Moissanite Pieces",
    moissaniteRate: "Moissanite Rate",

    motherOfPearlWeight: "Mother Of Pearl Weight",
    motherOfPearlPieces: "Mother Of Pearl Pieces",
    motherOfPearlRate: "Mother Of Pearl Rate",

    dialWeight: "Dial Weight",
    dialPieces: "Dial Pieces",
    dialRate: "Dial Rate",

    woodWeight: "Wood Weight",
    woodPieces: "Wood Pieces",
    woodRate: "Wood Rate",

    fancyWeight: "Fancy Weight",
    fancyPieces: "Fancy Pieces",
    fancyRate: "Fancy Rate",

    onyxWeight: "Onyx Weight",
    onyxPieces: "Onyx Pieces",
    onyxRate: "Onyx Rate",

    blueCsWeight: "Blue CS Weight",
    blueCsPieces: "Blue CS Pieces",
    blueCsRate: "Blue CS Rate",

    blueKCsWeight: "Blue-K CS Weight",
    blueKCsPieces: "Blue-K CS Pieces",
    blueKCsRate: "Blue-K CS Rate",

    greenCsWeight: "Green CS Weight",
    greenCsPieces: "Green CS Pieces",
    greenCsRate: "Green CS Rate",

    pinkCsWeight: "Pink CS Weight",
    pinkCsPieces: "Pink CS Pieces",
    pinkCsRate: "Pink CS Rate",

    flowerWeight: "Flower Weight",
    flowerPieces: "Flower Pieces",
    flowerRate: "Flower Rate",

    pearlWeight: "Pearl Weight",
    pearlPieces: "Pearl Pieces",
    pearlRate: "Pearl Rate",

    blueLWeight: "Blue-L Weight",
    blueLPieces: "Blue-L Pieces",
    blueLRate: "Blue-L Rate",

    blue2Weight: "Blue-2 Weight",
    blue2Pieces: "Blue-2 Pieces",
    blue2Rate: "Blue-2 Rate",

    blueTWeight: "Blue-T Weight",
    blueTPieces: "Blue-T Pieces",
    blueTRate: "Blue-T Rate",

    rubyWeight: "Ruby Weight",
    rubyPieces: "Ruby Pieces",
    rubyRate: "Ruby Rate",

    pinkKWeight: "Pink-K Weight",
    pinkKPieces: "Pink-K Pieces",
    pinkKRate: "Pink-K Rate",

    pinkLWeight: "Pink-L Weight",
    pinkLPieces: "Pink-L Pieces",
    pinkLRate: "Pink-L Rate",

    pink2Weight: "Pink-2 Weight",
    pink2Pieces: "Pink-2 Pieces",
    pink2Rate: "Pink-2 Rate",

    pinkTWeight: "Pink-T Weight",
    pinkTPieces: "Pink-T Pieces",
    pinkTRate: "Pink-T Rate",

    greenKWeight: "Green-K Weight",
    greenKPieces: "Green-K Pieces",
    greenKRate: "Green-K Rate",

    green2Weight: "Green-2 Weight",
    green2Pieces: "Green-2 Pieces",
    green2Rate: "Green-2 Rate",

    greenLWeight: "Green-L Weight",
    greenLPieces: "Green-L Pieces",
    greenLRate: "Green-L Rate",

    greenTWeight: "Green-T Weight",
    greenTPieces: "Green-T Pieces",
    greenTRate: "Green-T Rate",

    redKWeight: "Red-K Weight",
    redKPieces: "Red-K Pieces",
    redKRate: "Red-K Rate",

    redCsWeight: "Red CS Weight",
    redCsPieces: "Red CS Pieces",
    redCsRate: "Red CS Rate",

    redLWeight: "Red-L Weight",
    redLPieces: "Red-L Pieces",
    redLRate: "Red-L Rate",

    redTWeight: "Red-T Weight",
    redTPieces: "Red-T Pieces",
    redTRate: "Red-T Rate",

    yellowKWeight: "Yellow-K Weight",
    yellowKPieces: "Yellow-K Pieces",
    yellowKRate: "Yellow-K Rate",

    yellowCsWeight: "Yellow CS Weight",
    yellowCsPieces: "Yellow CS Pieces",
    yellowCsRate: "Yellow CS Rate",

    yellowLWeight: "Yellow-L Weight",
    yellowLPieces: "Yellow-L Pieces",
    yellowLRate: "Yellow-L Rate",

    yellowTWeight: "Yellow-T Weight",
    yellowTPieces: "Yellow-T Pieces",
    yellowTRate: "Yellow-T Rate",

    navratnaStoneWeight: "Navratna Stone Weight",
    navratnaStonePieces: "Navratna Stone Pieces",
    navratnaStoneRate: "Navratna Stone Rate",

    latkanWeight: "Latkan Weight",
    latkanPieces: "Latkan Pieces",
    latkanRate: "Latkan Rate",

    emeraldWeight: "Emerald Weight",
    emeraldPieces: "Emerald Pieces",
    emeraldRate: "Emerald Rate",

    coralWeight: "Coral Weight",
    coralPieces: "Coral Pieces",
    coralRate: "Coral Rate",

    hessoniteGametWeight: "Hessonite Gamet Weight",
    hessoniteGametPieces: "Hessonite Gamet Pieces",
    hessoniteGametRate: "Hessonite Gamet Rate",

    blueSapphireWeight: "Blue Sapphire Weight",
    blueSapphirePieces: "Blue Sapphire Pieces",
    blueSapphireRate: "Blue Sapphire Rate",

    catsEyeWeight: "Cats Eye Weight",
    catsEyePieces: "Cats Eye Pieces",
    catsEyeRate: "Cats Eye Rate",

    yellowSapphireWeight: "Yellow Sapphire Weight",
    yellowSapphirePieces: "Yellow Sapphire Pieces",
    yellowSapphireRate: "Yellow Sapphire Rate",

    blackBeadsWeight: "Black Beads Weight",
    blackBeadsPieces: "Black Beads Pieces",
    blackBeadsRate: "Black Beads Rate",

    palchaWeight: "Palcha Weight",
    palchaPieces: "Palcha Pieces",
    palchaRate: "Palcha Rate",

    rudrakshaWeight: "Rudraksha Weight",
    rudrakshaPieces: "Rudraksha Pieces",
    rudrakshaRate: "Rudraksha Rate",

    amethystSquareWeight: "Amethyst Square Weight",
    amethystSquarePieces: "Amethyst Square Pieces",
    amethystSquareRate: "Amethyst Square Rate",

    chalcedonyWeight: "Chalcedony Weight",
    chalcedonyPieces: "Chalcedony Pieces",
    chalcedonyRate: "Chalcedony Rate",

    garnetMarquiseWeight: "Garnet Marquise Weight",
    garnetMarquisePieces: "Garnet Marquise Pieces",
    garnetMarquiseRate: "Garnet Marquise Rate",

    pinkBeadsWeight: "Pink Beads Weight",
    pinkBeadsPieces: "Pink Beads Pieces",
    pinkBeadsRate: "Pink Beads Rate",

    emeraldBeadsWeight: "Emerald Beads Weight",
    emeraldBeadsPieces: "Emerald Beads Pieces",
    emeraldBeadsRate: "Emerald Beads Rate",

    rubyBeadsWeight: "Ruby Beads Weight",
    rubyBeadsPieces: "Ruby Beads Pieces",
    rubyBeadsRate: "Ruby Beads Rate",

    greenBeadsWeight: "Green Beads Weight",
    greenBeadsPieces: "Green Beads Pieces",
    greenBeadsRate: "Green Beads Rate",

    greenEmeraldWeight: "Green Emerald Weight",
    greenEmeraldPieces: "Green Emerald Pieces",
    greenEmeraldRate: "Green Emerald Rate",

    pinkPearWeight: "Pink Pear Weight",
    pinkPearPieces: "Pink Pear Pieces",
    pinkPearRate: "Pink Pear Rate",

    czSolitaireWeight: "CZ Solitaire Weight",
    czSolitairePieces: "CZ Solitaire Pieces",
    czSolitaireRate: "CZ Solitaire Rate",
  },

  diamondImportKey: {
    diamondQuantity: "Diamond Quantity",
    totalCarat: "Total Carat",

    shape: "Shape",
    color: "Color",

    "solitaireSize.length": "Solitaire Size Length",
    "solitaireSize.width": "Solitaire Size Width",

    "sieveSize.length": "Sieve Size Length",
    "sieveSize.width": "Sieve Size Width",

    cut: "Cut",
    clarity: "Clarity",

    polish: "Polish",
    symmetry: "Symmetry",
    fluorescenceIntensity: "Fluorescence Intensity",
    gradingLab: "Grading Lab",
  },

  businessType: {
    catalogist: "Catalogist",
    stockist: "Stockist",
  },

  diamondMasterData: {
    shape: [
      "Asscher",
      "Baguette",
      "Cushion",
      "Emerald",
      "Heart",
      "Marquise",
      "Oval",
      "Pear",
      "Princess",
      "Radiant",
      "Round",
      "Tapper Baguette",
    ],
    clarity: [
      "IF-VVS1",
      "IF",

      "VVS",
      "VVS1",
      "VVS2",
      "VVS-VS",

      "VS1",
      "VS2",

      "SI1",
      "SI2",
      "SI3",

      "I1",
      "I2",
      "I3",
    ],
    diamondType: { round: "Round", fancy: "Fancy" },
    cut: ["Fair", "Good", "Very Good", "Excellent"],
    polish: ["Fair", "Good", "Very Good", "Excellent"],
    symmetry: ["Fair", "Good", "Very Good", "Excellent"],
    fluorescenceIntensity: [
      "Very Strong",
      "Strong",
      "Medium",
      "Faint",
      "Very Slight",
      "None",
    ],
    gradingLab: ["GIA", "IGI"],
  },

  sessionStorage: {
    recentlySelectedPurity: "recentlySelectedPurity",
    recentSelectedColor: "recentSelectedColor",
  },

  colorOptions: {
    R: "R",

    W: "W",
    WR: "WR",

    Y: "Y",
    YR: "YR",
    YW: "YW",
    YRW: "YRW",
  },

  diamondColorOptions: {
    isSolitaire: {
      D: "D",
      E: "E",
      F: "F",
      G: "G",
      H: "H",
      I: "I",
      J: "J",
      K: "K",
      L: "L",
      M: "M",
    },
    nonSolitaire: {
      DEF: "DEF",
      EF: "EF",
      FG: "FG",
      GH: "GH",
    },
  },

  goldExtrasMaster: {
    setting: { open: "Open", close: "Close", openAndClose: "Open and Close" },
  },

  screws: {
    bombay: "Bombay",
    south: "South",
  },
};

export default enums;
