import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import closeIcon from "@ui/assets/data-engine/assets/ComponentIcons/close.png";
import correctIcon from "@ui/assets/data-engine/assets/ComponentIcons/correct.png";
import { defaultGoldHex, subscription } from "@ui/Utils/helper";
import "./imageResponseUI.css";
import PenguinGrid from "./PenguinGrid";

const ImageUpProgress = ({ reportArray = [], imageUploadInProgress }) => {
  const getMessageColor = (row) => {
    return row.large || row.largeFile || row.invalidImage || row.productNotFound
      ? "red"
      : subscription.cortex
      ? row.cortexErrorMessage === "No Jewel Found"
        ? defaultGoldHex
        : row.cortexErrorMessage || !row.cortexRun
        ? "red"
        : "green"
      : "green";
  };
  return reportArray.filter((e) => !e.success).length ? (
    <div>
      <TableContainer component={Paper} className="table-wrapper fade-in">
        <Table sx={{}} aria-label="simple table" className="table">
          <TableHead>
            <TableRow
              style={{
                position: "sticky",
                top: 0,
                zIndex: 15,
              }}
            >
              <TableCell
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                  maxWidth: "60px",
                }}
              >
                #
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                File Name
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Image
              </TableCell>
              {subscription.cortex ? (
                <TableCell
                  style={{
                    backgroundColor: "grey",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  AI
                </TableCell>
              ) : null}
              <TableCell
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Reason
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportArray.map((row, i) =>
              row.success ? null : (
                <TableRow key={i}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "center",
                      maxWidth: "60px",
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    {`${reportArray.length - i}`}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    {row.fileName}
                  </TableCell>

                  <TableCell style={{ textAlign: "center" }}>
                    <img
                      src={
                        row?.imageFail
                          ? closeIcon
                          : row.success
                          ? correctIcon
                          : closeIcon
                      }
                      style={{ height: 50, width: 50 }}
                    />
                  </TableCell>

                  {subscription.cortex ? (
                    <TableCell style={{ textAlign: "center" }}>
                      <img
                        src={row?.cortexRun ? correctIcon : closeIcon}
                        style={{ height: 50, width: 50 }}
                      />
                    </TableCell>
                  ) : null}

                  <TableCell component="th" scope="row">
                    <pre
                      style={{
                        color: getMessageColor(row),
                        fontWeight: "bolder",
                      }}
                    >
                      {row.invalidImage
                        ? "Invalid Image"
                        : row.large || row.largeFile
                        ? "Above 5 MB"
                        : row.productNotFound
                        ? "Product Not Found"
                        : subscription.cortex
                        ? row.cortexErrorMessage
                          ? row.cortexErrorMessage
                          : row.cortexRun
                          ? "Easy"
                          : "Retry"
                        : "Easy"}
                    </pre>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : imageUploadInProgress ? (
    <PenguinGrid />
  ) : null;
};

export const Review = () => {
  return (
    <pre>
      <h3 style={{ color: "grey", textAlign: "center" }}>Review</h3>
    </pre>
  );
};

export default ImageUpProgress;
