import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApi?.["data-engine"];

export const getDistinctZones = async () => {
  try {
    const url = baseApiUrl + "c/data-engine/products/getDistinctZones";

    const res = await HTTP.post(url, {});
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const checkDirty = (currentCondition) => {
  const {
    searchText,
    stockStatuses,
    categories,
    subCategories,
    collectionLines,
    purities,
    vendorCodes,
    zones,
    grossWeight,
    netWeight,
    imageFlag,
    locked,
    inferenceFormData,
    employees,
    customers,
    packageAction,
    packageDate,
  } = currentCondition;

  if (
    searchText ||
    (stockStatuses && stockStatuses.length) ||
    (categories && categories.length) ||
    (subCategories && subCategories.length) ||
    (collectionLines && collectionLines.length) ||
    (purities && purities.length) ||
    (vendorCodes && vendorCodes.length) ||
    (zones && zones.length) ||
    (grossWeight && (grossWeight.from || grossWeight.to)) ||
    (netWeight && (netWeight.from || netWeight.to)) ||
    imageFlag ||
    locked ||
    inferenceFormData ||
    (employees && employees.length) ||
    (customers && customers.length) ||
    packageAction ||
    (packageDate && (packageDate.startDate || packageDate.endDate))
  ) {
    return true;
  }

  return false;
};
