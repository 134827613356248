import React, { useState } from "react";
import { handleVerifyDataEngineImages } from "./excelImport";
import { Grid } from "@material-ui/core";
import DocUpload from "@ui/Controls/Upload/DocUpload";
import { chunkImagesUpload } from "../api";
import { subscription } from "@ui/Utils/helper";
import ImageUpProgress from "./ImageUpProgress";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import BurstModeOutlinedIcon from "@mui/icons-material/BurstModeOutlined";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import UploadStats from "./UploadStats";
// import TypingSpeed from "./games/TypingSpeed";
// import HandCricket from "./games/HandCricket";

const BulkImageUpload = () => {
  const [images, setImages] = useState([]);
  const [imagesFolder, setImagesFolder] = useState([]);
  const [verifyImageFiles, setVerifyImageFiles] = useState([]);

  const [readingFiles, setReadingFiles] = useState(false);
  const [display, setDisplay] = useState({});
  const [totalUploadStats, setTotalUploadStats] = useState({});
  const [firstChunk, setFirstChunk] = useState(null);
  // const [playGame, setPlayGame] = useState(false);

  const handleVerifyImages = () => {
    if (verifyImageFiles?.length)
      handleVerifyDataEngineImages(verifyImageFiles[0], setReadingFiles);
  };

  const calculateUploadStats = (
    startTime,
    avgChunkTime,
    completedChunks,
    totalChunks
  ) => {
    const currentTime = Date.now();
    const totalElapsedTime = currentTime - startTime;
    const timeLeft = avgChunkTime * (totalChunks - completedChunks);
    const averageETA = avgChunkTime * totalChunks;

    return {
      totalElapsedTime: parseInt(totalElapsedTime / 1000),
      timeLeft: parseInt(timeLeft / 1000),
      averageETA: parseInt(averageETA / 1000),
    };
  };

  const handleImagesUpload = async (files) => {
    const data = [],
      reportArray = [],
      fileSizeLimit = 5,
      payloadLimit = 5; // in mb.
    // lastModified: 1681473142860
    // lastModifiedDate: Fri Apr 14 2023 17:22:22 GMT+0530 (India Standard Time) {}
    // name: FILENAME.PNG
    // size: 12446 bytes
    // type: "image/png"
    // webkitRelativePath: ""

    if (files?.length > 0) {
      const total = files?.length || 0,
        successTotal = { de: 0, cortex: 0 };
      setDisplay({
        total,
        imageUploadInProgress: true,
      });
      setFirstChunk(Date.now());

      try {
        let isPremiumClient = "",
          count = 0,
          chunkDimension = 0,
          unlinkedImages = [],
          paginatedArray = [[]],
          fileSizeSum = 0,
          totalSize = 0,
          totalChunks = 0,
          startTime = 0,
          avgChunkTime = 0,
          completedChunks = 0,
          plannedETA,
          syncedCount = 0;

        // Calculate total size of all files
        for (let i = 0; i < files.length; i++) {
          const file = files[i],
            fileSize = Number(file.size) / 1000000; // bytes => mb conversion / 1000000

          if (
            fileSize > fileSizeLimit ||
            !file.type?.toString().toLowerCase().match("image")
          ) {
            reportArray.unshift({
              fileName: file.name,
              large: fileSize > fileSizeLimit,
              invalidImage: !file.type?.toString().toLowerCase().match("image"),
            });
          } else if (fileSizeSum + fileSize < payloadLimit) {
            fileSizeSum += fileSize;
            paginatedArray[chunkDimension] =
              paginatedArray[chunkDimension] || [];
            paginatedArray[chunkDimension].push(file);

            totalSize += file.size;
          } else {
            i--;
            fileSizeSum = 0;
            chunkDimension++;
          }
        }

        totalSize = (totalSize / (1024 * 1024)).toFixed(2);
        totalChunks = paginatedArray.length;
        startTime = Date.now();

        setTotalUploadStats({
          size: totalSize,
        });

        for (let j = 0; j < paginatedArray.length; j++) {
          try {
            const chunkStartTime = Date.now();
            const res = await chunkImagesUpload(paginatedArray[j], null, {
              loading: false,
            });
            const chunkEndTime = Date.now();
            completedChunks++;
            avgChunkTime =
              (avgChunkTime * (completedChunks - 1) +
                (chunkEndTime - chunkStartTime)) /
              completedChunks;
            const stats = calculateUploadStats(
              startTime,
              avgChunkTime,
              completedChunks,
              totalChunks
            );

            if (j === 0 || !plannedETA) {
              plannedETA = stats.timeLeft;
              setFirstChunk(null);
            }

            isPremiumClient = res?.isPremiumClient;

            res?.uploadedFiles &&
              res.uploadedFiles.map((uploadedFile) => {
                if (uploadedFile.cortexRun) {
                  successTotal.cortex++;
                }
                successTotal.de++;

                if (uploadedFile?.isProductExist) {
                  delete uploadedFile.isProductExist;
                  syncedCount++;
                  data.push(uploadedFile);
                  reportArray.unshift({
                    ...uploadedFile,
                    success:
                      typeof uploadedFile.cortexRun === "boolean" &&
                      !uploadedFile.cortexRun
                        ? false
                        : !uploadedFile.uploadFail,
                  });
                  count += 1;
                } else if (isPremiumClient) {
                  delete uploadedFile.isProductExist;
                  count += 1;
                  unlinkedImages.push(uploadedFile);
                  reportArray.unshift({
                    ...uploadedFile,
                    success:
                      typeof uploadedFile.cortexRun === "boolean" &&
                      !uploadedFile.cortexRun
                        ? false
                        : !uploadedFile.uploadFail,
                  });
                }
              });

            reportArray.unshift(...(res.notUploadedFiles || []));

            setDisplay({
              ...display,
              uploaded: count,
              syncedCount,
              isPremiumClient,
              total: total,
              successTotal,
              report: reportArray,
              progress: ((reportArray?.length / total) * 100).toFixed(2),
              imageUploadInProgress: !(j === paginatedArray.length - 1),
              timeLeft: stats.timeLeft,
            });
          } catch (err) {
            if (j === paginatedArray.length - 1) {
              setDisplay((prev) => ({
                ...prev,
                imageUploadInProgress: !(j === paginatedArray.length - 1),
              }));
            }
            console.log("failed in chunk upload: ", err);
            CustomEventEmitter.emit(
              "alert_error",
              "Uh oh! Something went wrong!"
            );
          }
        }

        const stats = calculateUploadStats(
          startTime,
          avgChunkTime,
          completedChunks,
          totalChunks
        );

        setTotalUploadStats({
          time: stats.totalElapsedTime,
          size: totalSize,
          plannedETA: plannedETA ? plannedETA : "Easy", // display in seconds,
        });

        CustomEventEmitter.emit(
          "alert_success",
          "Upload Complete. Syncing in progress..."
        );

        let message = `Imaged Linked to Products: ${data?.length}`;

        if (isPremiumClient) {
          message += ` \n\n Yet to be linked: ${unlinkedImages?.length}`;
        }

        CustomEventEmitter.emit("alert_success", message);
      } catch (err) {
        console.log(err);

        CustomEventEmitter.emit("alert_error", "Something went wrong!");
      } finally {
        setImages([]);
        setImagesFolder([]);
        setReadingFiles(false);
      }
    }
  };

  const handleImagesImport = () => {
    handleImagesUpload(Array.from(images));
  };

  const handleImagesFolderImport = () => {
    handleImagesUpload(Array.from(imagesFolder));
  };

  return (
    <Grid container spacing={3} style={{ minHeight: "720px", padding: "24px" }}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        style={{ marginLeft: "38px" }}
      >
        <Grid item xs={12} md={4} container direction="column">
          <h3 style={{ color: "#1d9bc2" }}>Bulk Image Upsert</h3>
          <Grid item xs={12}>
            <pre
              style={{ fontSize: "12px" }}
            >{`>> Bulk Default Image for Stocks \nex: fileName as DESIGN.png`}</pre>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <div>
            <DocUpload
              multiple
              accept="image/png, image/jpeg"
              files={images}
              setFiles={setImages}
              handleUpload={handleImagesImport}
              setReadingFiles={setReadingFiles}
              uploadIcon={{
                icon: BurstModeOutlinedIcon,
                iconOnHover: BurstModeIcon,
                type: "Component",
                style: { fontSize: "68px", color: "#1d9bc2" },
              }}
              tooltip={"Upload Multiple Images"}
              disabled={
                Array.from(images).length || Array.from(imagesFolder).length
              }
            />
          </div>
          <pre>Upload Multiple Images</pre>
        </Grid>

        <Grid item xs={12} md={4}>
          <div>
            <DocUpload
              multiple
              accept="image/png, image/jpeg"
              webkitdirectory="true"
              files={imagesFolder}
              setFiles={setImagesFolder}
              handleUpload={handleImagesFolderImport}
              setReadingFiles={setReadingFiles}
              uploadIcon={{
                icon: FolderCopyOutlinedIcon,
                iconOnHover: FolderCopyIcon,
                type: "Component",
                style: { fontSize: "68px", color: "#1d9bc2" },
              }}
              tooltip={"Upload Images Folder"}
              disabled={
                Array.from(images).length || Array.from(imagesFolder).length
              }
            />
          </div>
          <pre>Upload Images Folder</pre>
        </Grid>
      </Grid>

      {display.total >= 0 ? (
        <>
          <Grid item xs={12}>
            {/* {display?.imageUploadInProgress && ( */}
            <UploadStats
              eta={display.timeLeft}
              totalTimeTaken={totalUploadStats.time}
              totalSize={totalUploadStats.size}
              progress={display.progress || 0}
              display={display}
              subscription={subscription}
              plannedETA={totalUploadStats.plannedETA}
              firstChunk={firstChunk}
            />
            {/* )} */}
          </Grid>

          <Grid item xs={12}>
            <ImageUpProgress
              reportArray={display?.report || []}
              imageUploadInProgress={display?.imageUploadInProgress}
            />
          </Grid>
        </>
      ) : null}

      {/* <Grid item xs={12}>
            <DocUpload
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              files={verifyImageFiles}
              setFiles={setVerifyImageFiles}
              handleUpload={handleVerifyImages}
              setReadingFiles={setReadingFiles}
            />
            <div>Verify Images</div>
            {
              "(Upload Excel with Style No column having design numbers to Download missing Image List for Product Designs)"
            }
          </Grid> */}

      {/* <HandCricket /> */}
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        {playGame ? (
          <TypingSpeed setPlayGame={setPlayGame} />
        ) : (
          <button
            onMouseEnter={() => setPlayGame(true)}
            style={{ textAlign: "center" }}
          >
            Practice
          </button>
        )}
      </div> */}
      {/* </div> */}
    </Grid>
  );
};

export default BulkImageUpload;
