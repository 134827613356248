import { Grid, Typography } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useAuth } from "../AuthProvider";
import {
  imageExpired,
  isDivisionControlled,
  oopsNotFound,
  userDetails,
} from "@ui/Utils/helper";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import React, { useEffect, useState } from "react";
import { filterDivisionsWithUserAccess } from "@ui/Auth/api";
import { dynamicApiCall } from "@ui/ComponentUtils/blueprintAPIs";
import commonConfig from "config/commonConfig";
import { useLocation } from "react-router-dom";

const DivisionControl = ({}) => {
  const auth = useAuth();
  const [divisions, setDivisions] = useState([]);
  const [openDivisionModal, setOpenDivisionModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const userInfo = auth?.user;
    let division = window.sessionStorage.getItem("division");
    division = division && JSON.parse(division);
    const urlPaths = location.pathname?.toString().split("/");

    if (
      !(
        urlPaths.length < 3 ||
        urlPaths.find((path) => path?.toLowerCase() === "home")
      ) &&
      userInfo?._id &&
      !division?._id
    ) {
      if (isDivisionControlled && auth?.token && divisions?.length === 0) {
        (async () => {
          try {
            const res =
              (await dynamicApiCall(
                `${commonConfig.baseApiUrl}data-engine/divisions/search`,
                "post"
              )) || [];

            const finalData = filterDivisionsWithUserAccess(res, userInfo);
            setDivisions(finalData);

            if (finalData?.length) {
              setOpenDivisionModal(true);
            }
          } catch (err) {
            console.log(err);
          }
        })();
      }
    }
  }, [auth.user, auth.token, location.pathname, isDivisionControlled]);

  return (
    <Dialog open={openDivisionModal} fullScreen={true}>
      <DialogContent>
        <Grid container spacing={2} style={{ margin: "15px" }}>
          <Grid item xs={12} container justify="center">
            <LocationCityIcon style={{ fontSize: 100, color: "#1d9bc2" }} />
          </Grid>
          <Grid item xs={12} container justify="center">
            <Typography variant="h4">
              Welcome to {userDetails?.client?.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          spacing={2}
          style={{
            padding: "50px",
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
          }}
        >
          {divisions.map((div, divIndex) => (
            <Grid
              item
              xs={3}
              container
              justifyContent="center"
              key={divIndex}
              style={{
                cursor: "wait",
                transition: "transform 0.3s", // Added transition for smooth scaling
              }}
              onMouseOver={(event) => {
                event.target.style.transform = "scale(1.1)";
              }}
              onMouseLeave={(event) => {
                event.target.style.transform = "scale(1)";
              }}
              onClick={() => {
                sessionStorage.setItem("division", JSON.stringify(div));
                window.location.reload();
              }}
            >
              <center>
                <img
                  src={div.image?.signedUrl || oopsNotFound}
                  alt="Division"
                  style={{
                    height: 120,
                    width: 120,
                    objectFit: "cover",
                    borderRadius: "10px",
                    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
                  }}
                  onError={(event) => {
                    event.target.src = imageExpired;
                  }}
                />
                <Typography
                  variant="h5"
                  style={{ marginTop: "10px", fontWeight: "bold" }}
                >
                  {div.name}
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginTop: "5px",
                    fontFamily: "monospace",
                  }}
                >
                  {div.description}
                </Typography>
              </center>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DivisionControl;
