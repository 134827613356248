import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { globalUserControl, search } from "@ui/ComponentUtils/blueprintAPIs";
import {
  handleGlobalUserControl,
  isAdmin,
  isDivisionControlled,
} from "@ui/Utils/helper";
import { appModule } from "../../api";

import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import Emitter from "@ui/Utils/CustomEventEmitter";

const earthErrorMessage =
  "Country, States, City options failed to load. Reload to retry";

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const [vendorCodes, setVendorCodes] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models["vendor-portal"]?.users;

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const handleEarth = async () => {
    try {
      const tempCountries = (
        (await search(enums.models.iam.countries)) || []
      ).map((country) => country["name"]);

      setCountries(tempCountries);
    } catch (err) {
      CustomEventEmitter.emit("alert_error", earthErrorMessage);
    }
  };

  useEffect(() => {
    handleEarth();
  }, []);

  useEffect(() => {
    if (values.companyDetails?.address?.country) {
      (async () => {
        try {
          const tempStates = (
            (await search(enums.models.iam.states, {
              "country.name": values.companyDetails.address?.country,
            })) || []
          ).map((state) => state["name"]);

          setStates(tempStates);
        } catch (err) {
          CustomEventEmitter.emit("alert_error", earthErrorMessage);
        }
      })();
    }
  }, [values.companyDetails?.address?.country]);

  useEffect(() => {
    if (values.companyDetails?.address?.state) {
      (async () => {
        try {
          const tempCities = (
            (await search(enums.models.iam.cities, {
              "country.name": values.companyDetails.address?.country,
              "state.name": values.companyDetails?.address?.state,
            })) || []
          ).map((city) => city["name"]);

          setCities(tempCities);
        } catch (err) {
          CustomEventEmitter.emit("alert_error", earthErrorMessage);
        }
      })();
    }
  }, [values.companyDetails?.address?.state]);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await search(enums.models["vendor-portal"]?.vendorCodes);

        if (res?.[0]?.availableCodes) {
          setVendorCodes(res?.[0]?.availableCodes || []);
        }
      } catch (err) {
        console.log(err);
      }
    })();

    if (isDivisionControlled) {
      (async () => {
        try {
          const res =
            (await search(enums.models.divisions, {
              filterColumns: { _id: 1, name: 1 },
            })) || [];
          setDivisions(res);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, []);

  const entityFields = [
    {
      name: "firstName",
      label: "First Name",
      fieldType: "textField",
    },
    {
      name: "lastName",
      label: "Last Name",
      fieldType: "textField",
    },
    {
      name: "vendorCode",
      label: "Vendor Codes",
      fieldType: "dropdown",
      options: vendorCodes || [],
      multiple: true,
      getOptionSelected: (option, value) => option === value,
    },
    {
      name: "division",
      label: "Division",
      fieldType: enums.fieldTypes.dropdown,
      options: divisions || [],
      multiple: true,
      getOptionLabel: (option) => option?.name || "",
      getOptionSelected: (opt, val) => opt?.name === val?.name,
      hidden: !isDivisionControlled,
      render: "name",
    },
    {
      name: "phone",
      label: "Phone",
      fieldType: "textField",
      dataType: enums.dataTypes.number,
    },
    {
      name: "password",
      label: "Password",
      fieldType: "textField",
      hash: true,
    },
    {
      name: "email",
      label: "Email",
      fieldType: "textField",
    },
    {
      name: "companyDetails.name",
      label: "Company Name",
      fieldType: "textField",
    },

    {
      name: "companyDetails.address.country",
      label: "Country",
      fieldType: enums.fieldTypes.dropdown,
      options: countries,
      render: "companyDetails.address.country",
    },
    {
      name: "companyDetails.address.state",
      label: "State",
      fieldType: enums.fieldTypes.dropdown,
      options: states,
      render: "companyDetails.address.state",
    },
    {
      name: "companyDetails.address.city",
      label: "City",
      fieldType: enums.fieldTypes.dropdown,
      options: cities,
      render: "companyDetails.address.city",
    },

    {
      name: "companyDetails.address.street",
      label: "Street",
      fieldType: "textField",
    },
    {
      name: "companyDetails.address.pincode",
      label: "Pincode",
      fieldType: "textField",
    },
  ];

  const links = [
    {
      name: "Vendors",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: values.vendorCode?.join(", ") || "",
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
    {
      hidden: isEditable || !isAdmin,
      label: "Signout All Apps",
      iconName: "signout",
      tooltip: "Signout " + values.firstName,
      onClick: async () => {
        try {
          const res = await globalUserControl(
            enums.models["vendor-portal"].users,
            {
              users: [
                {
                  _id: values._id,
                  firstName: values.firstName,
                  lastName: values.lastName,
                },
              ],
            }
          );

          Emitter.emit(
            "alert_success",
            `Logged out ${res.nModified || 0} user successfully...`
          );
        } catch (_) {
          Emitter.emit("alert_error", "Something went wrong!");
        }
      },
      color: "primary",
    },
    {
      hidden: isEditable || !isAdmin,
      label: values.disabled ? "Approve User" : "Disable User",
      iconName: values.disabled ? "disable" : "update",
      tooltip: values.disabled ? "Approve User" : "Disable User",
      onClick: async () => {
        await handleGlobalUserControl(
          {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
            iamControl: { disabled: !values.disabled },
          },
          [appModule]
        );

        window.location.reload();
      },
      color: "primary",
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Vendors"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
