import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getSingleRecord,
  updateListRecords,
  getPaginatedData,
  initPrinterTemplates,
  handleLocalTagUpdate,
} from "../../api";
import MatTable from "@ui/components/MatTable";
import { useRef } from "react";
import enums from "helpers/enums";
import {
  connectPrinter,
  defaultGoldHex,
  exportExcel,
  formatDateToRead,
  getProductImageUri,
  imageExpired,
  isAdmin,
  isVendor,
  printFromConnectedDevice,
  roundOff,
  userDetails,
} from "@ui/Utils/helper";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { Grid, Button } from "@material-ui/core/index";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MyAutocomplete, MyCarousel } from "@ui/MuiComponents/index";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterByFields, {
  getMongooseCriteria,
} from "@ui/Controls/Search/FilterByFields";
import { sheetNames } from "@ui/Utils/excelImportHelpers";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import HistoryIcon from "@mui/icons-material/History";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import ScrollTopButton from "@ui/Actions/Scroll/ScrollTopButton";
import ProductDetailPopup from "@ui/MuiComponents/Carousel/ProductDetailPopup";
import LazyLoad from "@ui/Actions/LazyLoad/index";
import commonConfig from "config/commonConfig";

// used sessionStorage keys are,
// 1. data-engine/products/printSearch
// 2. data-engine/products/printPagination

const searchSessionName = enums.models["data-engine"].products + "/printSearch";
const paginationSessionName =
  enums.models["data-engine"].products + "/printPagination";

const ProductsList = () => {
  const [listPageTableQuery, setListPageTableQuery] = useState({
    pageSize: 100,
    page: 0,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [metaInfo, setMetaInfo] = useState({});
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [defaultCondition, setDefaultCondition] = useState();
  const [openPrintDialog, setOpenPrintDialog] = useState(false);
  const [openPrintHistory, setOpenPrintHistory] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);

  const navigate = useNavigate();
  const tableRef = useRef();
  const model = enums.models["data-engine"].products;

  useEffect(() => {
    (async () => {
      await initPrinterTemplates(setTemplates, setSelectedTemplate);
    })();
  }, []);

  // useEffect(() => {
  //   try {
  //     let searchFilter = window.sessionStorage.getItem(searchSessionName);
  //     searchFilter = searchFilter ? JSON.parse(searchFilter) : { styleNo: [] };

  //     setDefaultCondition(searchFilter);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  const onClickStyle = {
    cursor: "pointer",
    height: 100,
    display: "flex",
    alignItems: "center",
  };
  const columns = [
    {
      title: "QR",
      render: (row) => (
        <LazyLoad>
          <img
            src={row.qrCodeValue}
            style={{ height: 100, width: 100, cursor: "progress" }}
            onClick={() => setOpenPreview({ qr: row.code })}
          />
          {openPreview?.qr === row.code ? (
            <MyCarousel
              setOpenPreview={() => setOpenPreview({})}
              openPreview={true}
              items={[
                {
                  fileName: row.sku,
                  fileType: "image/png",
                  signedUrl: row.qrCodeValue,
                },
              ]}
            />
          ) : null}
        </LazyLoad>
      ),
    },
    {
      title: "Category",
      field: "category.name",
      render: (row) => (
        <div
          style={onClickStyle}
          onClick={() => navigate(`/${model}/${row.code}`)}
        >
          {row?.category?.name}
        </div>
      ),
    },
    {
      title: "SKU",
      field: "sku",
      render: (row) => (
        <div
          style={onClickStyle}
          onClick={() => navigate(`/${model}/${row.code}`)}
        >
          {row.sku}
        </div>
      ),
    },
    {
      title: "Design",
      field: "styleNo",
      render: (row) => (
        <div
          style={onClickStyle}
          onClick={() => navigate(`/${model}/${row.code}`)}
        >
          {row.styleNo}
        </div>
      ),
    },
    {
      title: "Gross, Net",
      field: "grossWeight",
      render: (row) => {
        const grossWeight = (row.grossWeight || 0).toFixed(3);
        const netWeight = (row.netWeight || 0).toFixed(3);
        // const operator = row.grossWeight === row.netWeight ? "=" : ">";
        const operator = ",";

        return (
          <pre
            style={{
              cursor: "pointer",
              height: 100,
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "space-between", // Center horizontally if needed
              width: "100%", // Optional: Make it full width
            }}
            onClick={() => navigate(`/${model}/${row.code}`)}
          >
            {`${grossWeight}, ${netWeight}`}
          </pre>
        );
      },
    },
    {
      title: "Since",
      field: "dateCreated",
      render: (row) => (
        <div
          style={{
            cursor: "pointer",
            height: 100,
            display: "flex",
            alignItems: "center", // Center vertically
            justifyContent: "center", // Center horizontally if needed
            flexDirection: "column", // Stack children vertically
            width: "100%", // Optional: Make it full width
          }}
          onClick={() => navigate(`/${model}/${row.code}`)}
        >
          <div style={{ width: "100%" }}>
            {" "}
            {/* Full width for date */}
            {formatDateToRead(row.dateCreated)}
          </div>
          <div style={{ fontSize: "12px", fontStyle: "italic", width: "100%" }}>
            {(row.createdBy?.firstName || "") +
              " " +
              (row.createdBy?.lastName || "")}
          </div>
        </div>
      ),
    },
    {
      title: "Image",
      render: (row) => (
        <LazyLoad>
          <img
            src={getProductImageUri(row)}
            alt="img"
            style={{
              height: 100,
              width: 100,
              objectFit: "contain",
              cursor: "progress",
            }}
            onError={(event) => {
              event.target.src = imageExpired;
            }}
            onClick={() => setOpenPreview({ product: row.code })}
          />
          {openPreview?.product === row.code ? (
            <ProductDetailPopup
              onClose={() => setOpenPreview({})}
              open={true}
              product={row}
            />
          ) : null}
        </LazyLoad>
      ),
    },
  ];

  const getData = async (query) => {
    //backend sorting
    if (query.orderBy) {
      query.sort = {
        [query.orderBy.field]: query.orderDirection,
      };
    }
    query = {
      ...query,
      qrCodeField: "sku",
    };

    try {
      let searchFilter = window.sessionStorage.getItem(searchSessionName);
      searchFilter = searchFilter ? JSON.parse(searchFilter) : { styleNo: [] };

      let pagination = {
        page: query.page,
        pageSize: query.pageSize,
      };

      if (!defaultCondition) {
        // so that it'll only set once..
        setDefaultCondition(searchFilter);

        pagination = window.sessionStorage.getItem(paginationSessionName);
        pagination = pagination ? JSON.parse(pagination) : listPageTableQuery;

        query.page = pagination.page;
        query.pageSize = pagination.pageSize;
        setListPageTableQuery({ page: query.page, pageSize: query.pageSize });
      } else {
        setListPageTableQuery({ page: query.page, pageSize: query.pageSize });
      }

      query.criteria = [getMongooseCriteria(searchFilter), {}];

      if (isVendor) {
        query.criteria[0]["createdBy._id"] = userDetails._id;
        query.criteria[0]["stockStatus"] = enums.stockStatus.staged;
      }

      // let stockWiseFilter = window.sessionStorage.getItem(stockWiseSessionName);
      // if (stockWiseFilter) {
      //   stockWiseFilter = JSON.parse(stockWiseFilter);
      // }

      // query.stockWise = stockWiseFilter;

      let response = await getPaginatedData(model, query);

      if (response && response.paginationInfo) {
        let records = response.records || [];
        setMetaInfo(response.paginationInfo || {});

        return {
          data: records || [],
          page: response.paginationInfo.currentPageNo - 1 || 0,
          totalCount: response.paginationInfo.totalRecords || 0,
        };
      }
    } catch (error) {
      console.log(error);
      setMetaInfo({});
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const tableActions = [
    {
      icon: "print",
      tooltip: "Print",
      disabled: !selectedRows?.length,
      isFreeAction: false,
      onClick: (_) => {
        setOpenPrintDialog(true);
      },
    },
    {
      icon: () => <FileDownloadIcon />,
      tooltip: "Export excel for " + selectedRows.length + " rows ",
      disabled: !selectedRows?.length,
      isFreeAction: false,
      onClick: async (_) => {
        try {
          const tempSelectedRows = selectedRows.map((row) => ({
            ...row,
            netWeight: Number(row.netWeight || 0).toFixed(3),
            grossWeight: Number(row.grossWeight || 0).toFixed(3),
          }));
          const configuration = await getSingleRecord(
            enums.models["data-engine"].configuration
          );
          if (!configuration?._id) {
            return CustomEventEmitter.emit(
              "alert_error",
              "Configuration not found."
            );
          }

          const primaryKeys = enums.productsImportKey;
          delete primaryKeys.lessWeight;
          delete primaryKeys.stockStatus;

          const excelColumns = Object.keys(primaryKeys).map((fieldName) => {
            let field = fieldName;

            if (
              fieldName === "category" ||
              fieldName === "subCategory" ||
              fieldName === "collectionLine"
            ) {
              field += ".name";
            }

            const obj = {
              title: primaryKeys[fieldName],
              field: field,
              width: 20,
            };

            if (fieldName === "purity") {
              obj.field += ".value";
              obj.extra = " KT";
            }
            return obj;
          });

          (configuration.fields || []).map((field) => {
            if (field.checked && !field.priceField) {
              let fieldLabel = field.label;

              if (field.priceField) {
                fieldLabel += field.pricePerWeight
                  ? " (perWeight)"
                  : field.pricePerPiece
                  ? " (perPiece)"
                  : "";
              }
              excelColumns.push({
                title: fieldLabel,
                field: field.name,
                width:
                  fieldLabel?.toString().length > 21
                    ? 40
                    : fieldLabel?.toString().length > 17
                    ? 30
                    : 20,
              });
            }
          });

          const diamondColumns = [
            { title: enums.productsImportKey.sku, field: "sku", width: 20 },
            ...Object.keys(enums.diamondImportKey).map((key) => ({
              field: key,
              title: enums.diamondImportKey[key],
              width: 20,
            })),
          ];

          const sheetsArr = [
            {
              data: tempSelectedRows,
              columns: excelColumns,
              sheetName: sheetNames.products,
            },
          ];

          if (configuration.diamondFields) {
            sheetsArr.push({
              data: tempSelectedRows,
              columns: diamondColumns,
              sheetName: sheetNames.diamondDetails,
            });
          }

          exportExcel(sheetsArr, "Stocks");
        } catch (err) {
          console.log(err);
          CustomEventEmitter.emit("alert_error", "Something went wrong");
        }
      },
    },
  ];

  const onSelectionChange = (rows) => {
    let currentPageNum = tableRef.current.state.query.page;
    if (selectedRows && selectedRows.length + 1 < currentPageNum) {
      for (let index = selectedRows.length; index < currentPageNum; index++) {
        selectedRows[index] = [];
      }
    }

    selectedRows && selectedRows.splice(currentPageNum, 1, rows);
    setSelectedRows(rows);
  };

  const handleFieldLevelSearch = (filterQuery = []) => {
    window.sessionStorage.setItem(
      enums.models["data-engine"].products + "/printPagination",
      JSON.stringify({ page: 0, pageSize: listPageTableQuery.pageSize })
    );

    const queryData = {
      pageSize: listPageTableQuery.pageSize,
      page: 0,
      criteria: [filterQuery, {}],
    };

    tableRef.current.onQueryChange(queryData);
  };

  const handlePrint = async () => {
    if (!selectedTemplate?.name || !selectedRows?.length) {
      return CustomEventEmitter.emit("alert_error", "Invalid Print");
    }
    try {
      let connectedDevice = (await navigator.usb.getDevices()) || [];
      connectedDevice = connectedDevice.find((device) => {
        if (device.vendorId) {
          return true;
        }
      });

      const device = connectedDevice || (await connectPrinter());

      if (!device) {
        return CustomEventEmitter.emit("alert_error", "Connect Printer First");
      }

      for (let i = 0; i < selectedRows.length; i++) {
        const stock = selectedRows[i]; // its secretly used from eval below.. XD

        const printCommand = eval(selectedTemplate?.tsplCommands);
        await printFromConnectedDevice(device, printCommand);
      }

      const updatedRows = await updateListRecords(
        enums.models["data-engine"].products,
        selectedRows.map((row) => {
          return {
            code: row.code,
            printHistory: [
              ...(row.printHistory || []),
              {
                dateCreated: new Date(),
                createdBy: {
                  _id: userDetails._id,
                  firstName: userDetails.firstName,
                  lastName: userDetails.lastName,
                  role: userDetails.role,
                  vendorCode: userDetails.vendorCode,
                },
              },
            ],
          };
        })
      );

      const currentRows = tableRef.current.dataManager.data;

      // Update the specific row
      updatedRows.map((updatedRow) => {
        currentRows.find((row, rowIndex) => {
          if (row._id === updatedRow._id) {
            currentRows[rowIndex].printHistory = updatedRow.printHistory;
            return true;
          }
        });
      });

      // Force table to re-render
      tableRef.current.onQueryChange();

      CustomEventEmitter.emit("alert_success", "Printed Successfully");
      setOpenPrintDialog(false);
    } catch (err) {
      console.log(err);
      const message =
        err?.response?.data?.message || err?.message || "Something went wrong.";
      CustomEventEmitter.emit("alert_error", message);
    }
  };

  return (
    <div>
      <Grid
        item
        xs={12}
        container
        style={{ marginLeft: "24px" }}
        // direction="column"
        // alignItems="center"
      >
        <Grid item xs={11} style={{ padding: "8px", marginBottom: "8px" }}>
          <pre>
            <h3 style={{ color: "#1d9bc2" }}>{"Printing Station"}</h3>
          </pre>
        </Grid>
      </Grid>

      <div
        style={{ marginBottom: "16px", marginTop: "-48px", padding: "28px" }}
      >
        <FilterByFields
          metaInfo={metaInfo}
          handleFieldLevelSearch={handleFieldLevelSearch}
          defaultCondition={defaultCondition}
          searchSessionName={searchSessionName}
          printingStationScreen={true}
        />
      </div>

      <MatTable
        tableRef={tableRef}
        title={
          <MyHoverIcons
            muiIcon={<HistoryIcon />}
            muiIconHover={<HistoryIcon style={{ color: defaultGoldHex }} />}
            tooltip={"History of printing"}
            // style={{ backgroundColor: "#81a1d4", color: "white" }}
            onClick={() => setOpenPrintHistory(true)}
          />
        }
        actions={tableActions}
        columns={columns}
        data={(query) => getData(query)}
        options={{
          search: false,
          pageSize: listPageTableQuery.pageSize,
          selection: true,
          pageSizeOptions: [
            25, 50, 100, 200, 500, 1000, 2500,
            // 5000, 10000, 25000,
          ],
          exportButton: false,
          // exportCsv: (data, columns) => exportExcel([{data, columns, sheetName: "Stocks"}], "Stocks"),
        }}
        showSelectAllCheckbox={true}
        // onRowClick={(_, selectedRow) => {
        //   navigate(`/${model}/${selectedRow.code}`);
        // }}
        onSelectionChange={onSelectionChange}
        onChangePage={(page) => {
          const info = {
            ...listPageTableQuery,
            page: page,
          };

          window.sessionStorage.setItem(
            paginationSessionName,
            JSON.stringify(info)
          );
          setListPageTableQuery(info);
        }}
        onChangeRowsPerPage={(rowsPerPage) => {
          const info = {
            ...listPageTableQuery,
            pageSize: rowsPerPage,
          };

          window.sessionStorage.setItem(
            paginationSessionName,
            JSON.stringify(info)
          );
          setListPageTableQuery(info);
        }}
      />

      <ScrollTopButton />

      {openPrintDialog ? (
        <Dialog
          fullWidth
          open={openPrintDialog}
          onClose={() => setOpenPrintDialog(false)}
        >
          <DialogTitle onClose={() => setOpenPrintDialog(false)}>
            Select Printemplate
          </DialogTitle>
          <DialogContent>
            <MyAutocomplete
              options={templates || []}
              getOptionLabel={(option) => option?.name || ""}
              getOptionSelected={(opt, val) => opt?.name === val?.name}
              disableClearable={true}
              onChange={(_, v) => {
                handleLocalTagUpdate(v, setSelectedTemplate);
              }}
              value={selectedTemplate}
            />

            <Grid container item xs={12} justify="flex-end">
              <Button
                variant="contained"
                style={{
                  marginRight: "24px",
                  marginTop: "24px",
                  marginBottom: "24px",
                }}
                color="primary"
                onClick={handlePrint}
              >
                Print {selectedRows.length} tags
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}

      {openPrintHistory ? (
        <PrintHistory
          setOpenPrintHistory={setOpenPrintHistory}
          openPrintHistory={openPrintHistory}
        />
      ) : null}
    </div>
  );
};

const PrintHistory = ({ setOpenPrintHistory, openPrintHistory }) => {
  const historyColumns = [
    {
      title: "SKU",
      field: "sku",
    },
    {
      title: "Design No",
      field: "styleNo",
    },
    {
      title: "Gross",
      field: "grossWeight",
      render: (row) => roundOff(row.grossWeight, { weight: true }),
    },
    {
      title: "Net",
      field: "netWeight",
      render: (row) => roundOff(row.netWeight, { weight: true }),
    },
    {
      title: "Printed By",
      // headerStyle: {
      //   textAlign: "center",
      // },
      render: (row) => {
        return (
          <ul>
            {(row.printHistory || [])
              .filter((e) =>
                isAdmin ? true : e.createdBy._id === userDetails._id
              )
              .map((history, i) => (
                <li key={i}>
                  {`${history.createdBy?.firstName} ${
                    history.createdBy?.lastName
                  }${
                    history.createdBy?.vendorCode?.length
                      ? `, [${history.createdBy?.vendorCode.join(", ")}]`
                      : ""
                  }`}
                </li>
              ))}
          </ul>
        );
      },
    },
    {
      title: "Printed Date",
      // headerStyle: {
      //   textAlign: "center",
      // },
      render: (row) => {
        return (
          <ul>
            {(row.printHistory || [])
              .filter((e) =>
                isAdmin ? true : e.createdBy._id === userDetails._id
              )
              .map((history, i) => (
                <li key={i}>
                  {`${
                    history.dateCreated &&
                    formatDateToRead(
                      history.dateCreated,
                      globalDateTimeFormat.read
                    )
                  }`}
                </li>
              ))}
          </ul>
        );
      },
    },
  ];

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openPrintHistory}
      onClose={() => setOpenPrintHistory(false)}
    >
      <DialogTitle
        onClose={() => setOpenPrintHistory(false)}
        style={{ margin: "8px", height: "100%", width: "100%" }}
      >
        <div>History of printing</div>
      </DialogTitle>
      <DialogContent>
        <div style={{ margin: "8px", height: "100%", width: "100%" }}>
          <GeneralListPage
            columns={historyColumns}
            model={enums.models["data-engine"].products}
            headerTitle="Stocks"
            searchFields={["sku", "styleNo"]}
            numberFields={["grossWeight", "netWeight"]}
            dateFields={["printHistory.dateCreated"]}
            hideAction={{ delete: true, header: true, selection: true }}
            onRowClick={false}
            mainQuery={{
              // sort: {
              //   // "printHistory.date": -1,
              // },
              filterColumns: {
                sku: 1,
                styleNo: 1,
                printHistory: 1,
                grossWeight: 1,
                netWeight: 1,
                // code: 1 // uncomment this if you have onRowClick as true
              },
              criteria: [
                isVendor
                  ? { "printHistory.createdBy._id": userDetails._id }
                  : { printHistory: { $exists: true, $nin: [[], null] } },
                {},
              ],
            }}
            customBaseApi={commonConfig.baseApi["data-engine"]}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProductsList;
