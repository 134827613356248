import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ButtonContainer = styled.span`
  position: fixed;
  bottom: 32px;
  right: 32px;
  align-items: center;
  height: 32px;
  width: 32px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  opacity: 0.5;
  background: #3b3b98;
  border-radius: 4px;
  transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
  display: ${({ isScrollButtonVisible }) =>
    isScrollButtonVisible ? "flex" : "none"};
  &:hover {
    opacity: 1;
  }
`;

const ScrollTopButton = ({ style }) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkScrollHeight = () => {
      if (!showButton && window.pageYOffset > 400) {
        setShowButton(true);
      } else if (showButton && window.pageYOffset <= 400) {
        setShowButton(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === "ArrowUp") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      if (event.metaKey && event.key === "ArrowDown") {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
    };

    window.addEventListener("scroll", checkScrollHeight);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("scroll", checkScrollHeight);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showButton]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ButtonContainer
      isScrollButtonVisible={showButton}
      onClick={scrollToTop}
      style={style}
    >
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12 19V5M5 12l7-7 7 7" />
      </svg>
    </ButtonContainer>
  );
};

export default ScrollTopButton;

/*
pageYOffset is deprecated, use below ASAP....

import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ButtonContainer = styled.span`
  position: fixed;
  bottom: 32px;
  right: 32px;
  align-items: center;
  height: 40px;
  width: 40px;
  justify-content: center;
  z-index: 1000000000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  opacity: 0.5;
  background: #3b3b98;
  border-radius: 50%;
  transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  &:hover {
    opacity: 1;
  }
`;

const ScrollTopButton = ({ style, pageYOffset = 400 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkScrollHeight = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      setIsVisible(scrollTop > pageYOffset);
    };

    window.addEventListener("scroll", checkScrollHeight);
    return () => window.removeEventListener("scroll", checkScrollHeight);
  }, []); // Run only once, event listener handles updates

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  return (
    <ButtonContainer isVisible={isVisible} onClick={scrollToTop} style={style}>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12 19V5M5 12l7-7 7 7" />
      </svg>
    </ButtonContainer>
  );
};

export default ScrollTopButton;

*/
