import React, { useRef } from "react";
import useOnScreen from "@ui/Hooks/useOnScreen";

const LazyLoad = ({ children, fallback = null, ...props }) => {
  const ref = useRef(); // Create a ref for the element
  const [isIntersecting] = useOnScreen(ref); // Use the custom useOnScreen hook

  return (
    <div ref={ref} {...props}>
      {isIntersecting ? children : fallback}
    </div>
  );
};

export default LazyLoad;

/*
import React, { useRef } from "react";
import PropTypes from "prop-types";
import useOnScreen from "@ui/Hooks/useOnScreen";

const LazyLoad = ({
  children,
  fallback = null,
  preloadNext = null,
  ...props
}) => {
  const ref = useRef(); // Create a ref for the element
  const isIntersecting = useOnScreen(ref, { threshold: 0.1 }); // Use the custom useOnScreen hook

  // Preload next items if specified
  if (isIntersecting && preloadNext) {
    preloadNext();
  }

  return (
    <div ref={ref} {...props}>
      {isIntersecting ? children : fallback}
    </div>
  );
};

LazyLoad.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  preloadNext: PropTypes.func, // Function to preload next items
};

export default LazyLoad;

*/
