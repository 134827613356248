/**
 * @author Abhishek K
 * created on December 5, 2020,
 * updated on March 5, 2024
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import getDateRange from "./getDateRange";
import EnhancedFilterBar from "./EnhancedFilterBar";
import { Grid, InputAdornment } from "@material-ui/core";
import DateRangePickerModal from "@ui/ComponentUtils/DateRange/DateRangePickerModal";
import VoiceSearch from "./VoiceSearch";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import ClearIcon from "@mui/icons-material/Clear";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

export const dateBetweenSeperator = " -- ";

const AdvanceSearchFilter = ({
  searchFields,
  // *Send searchFields for model fields names to search with 'String'. ex: ['name', description']
  numberFields,
  // *Send numberFields for model fields names to search with 'number' ex: ['id']
  userFields = ["$createdBy.firstName", "$createdBy.lastName"],
  // *userFields, anyhow user Based search will happen in backend, but if you model stores user data as an Object without ref, then send those field names,
  // if it is stored in data.createdBy, prefix $ and send, ex: ['$data.createdBy.firstName', '$data.createdBy.lastName']
  // together as this file is giving concat Names Query
  clearFilterData,
  // *clearFilterData prop should be sent with true value when you want to clear filterData / searchBar data
  extras,
  // *extras is an array field if some extra data needs to be put in searchBar from code (not from user input)
  dateFields = ["dateCreated"],
  // dateFields can be sent to match what all date fields.. ex: ['createdDate, updatedDate]
  ...props
  // destructure remaining soon.. ;)
}) => {
  const [filterData, setFilterData] = useState([]);
  const initialDateRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const dateFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  // useEffect(() => {
  // 	try {
  // 		const userLang = RM.helper().getLocale(); // to set lang for Date Range Picker

  // 		switch (userLang) {
  // 			case 'en':
  // 				setDatePickerLocale(enUS);
  // 				break;

  // 			case 'fr':
  // 				setDatePickerLocale(fr);
  // 				break;

  // 			case 'es':
  // 				setDatePickerLocale(es);
  // 				break;
  // 		}
  // 	} catch (err) {
  // 		console.log(err);
  // 	}
  // }, []);

  useEffect(() => {
    if (clearFilterData) {
      resetFilter();
      setSelectedDateRange(initialDateRange);
    }
  }, [clearFilterData]);

  useEffect(() => {
    if (
      extras &&
      extras.length &&
      extras.toString() !== filterData.toString()
    ) {
      setFilterData(extras);
      getValues(extras);
    }
  }, [extras]);

  const handleDateSearch = () => {
    // to remove old date range from filterData
    const newFilterData = filterData.filter((element) => {
      const previousDates = element.split(dateBetweenSeperator);
      if (
        !(
          isNaN(previousDates[0]) &&
          !isNaN(Date.parse(previousDates[0])) &&
          isNaN(previousDates[1]) &&
          !isNaN(Date.parse(previousDates[1]))
        )
      ) {
        return true;
      }
    });

    getValues(
      newFilterData,
      selectedDateRange.startDate,
      selectedDateRange.endDate
    );
  };

  const regexPurify = (element) => {
    return element.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const getValues = (searchElements, selectedFromDate, selectedToDate) => {
    searchElements = searchElements.map((element) => element.toString().trim());

    let filterQuery = [],
      filterQueryArray = [],
      mFromDate,
      mToDate,
      formattedFromDate,
      formattedToDate;

    searchElements.forEach((element) => {
      const previousDates = element.split(dateBetweenSeperator);

      if (
        (isNaN(previousDates[0]) && !isNaN(Date.parse(previousDates[0]))) ||
        isNaN(element)
      ) {
        let onlyDatesQuery = [];

        if (isNaN(previousDates[1]) && !isNaN(Date.parse(previousDates[1]))) {
          // Will get here if element has date range pattern -> Dec 01, 2020 -- Dec 06, 2020
          mFromDate = moment(previousDates[0]).startOf("day").toString();
          mToDate = moment(previousDates[1]).endOf("day").toString();

          formattedFromDate = new Date(mFromDate).toLocaleDateString(
            "en-US",
            dateFormatOptions
          );

          formattedToDate = new Date(mToDate).toLocaleDateString(
            "en-US",
            dateFormatOptions
          );

          dateFields.map((dateField) => {
            onlyDatesQuery.push({
              [dateField]: {
                $gte: mFromDate,
                $lte: mToDate,
              },
            });
          });

          // these 2 below IFs to attach other fields when date is found in Search Bar
          if (searchFields) {
            // send all String property fields in searchFields array
            onlyDatesQuery = [
              ...onlyDatesQuery,
              ...searchFields.map((field) => ({
                [field]: { $regex: regexPurify(element), $options: "i" },
              })),
            ];
          }
          if (!isNaN(element) && numberFields) {
            // If the collection has any field with Number property, send those fields as numberFields
            onlyDatesQuery = [
              ...onlyDatesQuery,
              ...numberFields.map((field) => ({
                [field]: Number(element),
              })),
            ];
          }

          filterQuery = filterQuery.concat({ $or: onlyDatesQuery });
          return;
        } else if (!isNaN(Date.parse(element))) {
          // will get here when the filterData has single date as an element
          mFromDate = moment(element).startOf("day").toString();
          mToDate = moment(element).endOf("day").toString();

          dateFields.map((dateField) => {
            onlyDatesQuery.push({
              [dateField]: {
                $gte: mFromDate,
                $lte: mToDate,
              },
            });
          });

          // these 2 below IFs to attach other fields when date is found in Search Bar
          if (searchFields) {
            // send all String property fields in searchFields array
            onlyDatesQuery = [
              ...onlyDatesQuery,
              ...searchFields.map((field) => ({
                [field]: { $regex: regexPurify(element), $options: "i" },
              })),
            ];
          }
          if (!isNaN(element) && numberFields) {
            // If the collection has any field with Number property, send those fields as numberFields
            onlyDatesQuery = [
              ...onlyDatesQuery,
              ...numberFields.map((field) => ({
                [field]: Number(element),
              })),
            ];
          }

          filterQuery = filterQuery.concat({ $or: onlyDatesQuery });
          return;
        }
      }

      if (searchFields) {
        // send all String property fields in searchFields array
        filterQueryArray = searchFields.map((field) => {
          return {
            [field]: { $regex: regexPurify(element), $options: "i" },
          };
        });
      }

      let from = undefined,
        to = undefined;

      // Constants matching to Commands.json file in @ui will return respective from & to Dates
      let dateRange = getDateRange(element);
      from = dateRange.from;
      to = dateRange.to;

      if (from && to) {
        dateFields.map((dateField) => {
          filterQueryArray.push({
            [dateField]: {
              $gte: from,
              $lte: to,
            },
          });
        });
      }

      if (!isNaN(element) && numberFields) {
        // If the collection has any field with Number property, send those fields as numberFields
        numberFields.map((field) =>
          filterQueryArray.push({
            [field]: Number(element),
          })
        );
      }

      if (userFields) {
        // If createdBy is not ref and stored as Object data, send the field names like this.. firstName followed by lastName
        // ['$data.createdBy.firstName','$data.createdBy.lastName','$data.updatedBy.firstName','$data.updatedBy.lastName',]
        for (let i = 0; i < userFields.length - 1; i += 2) {
          filterQueryArray.push({
            $expr: {
              $regexMatch: {
                input: { $concat: [userFields[i], " ", userFields[i + 1]] },
                regex: regexPurify(element),
                options: "i",
              },
            },
          });
        }
      }

      if (filterQueryArray.length > 0) {
        filterQuery = filterQuery.concat({ $or: filterQueryArray });
      }
    });

    if (selectedFromDate && selectedToDate) {
      // will get here only when dateRange is selected from Calendar UI
      const fromDate = moment(selectedFromDate).startOf("day").toString();
      const toDate = moment(selectedToDate).endOf("day").toString();

      filterQuery = filterQuery.concat({
        $or: dateFields.map((dateField) => {
          return {
            [dateField]: {
              $gte: fromDate,
              $lte: toDate,
            },
          };
        }),
      });

      // To Format and show in SearchBar
      formattedFromDate = new Date(fromDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      );
      formattedToDate = new Date(toDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      );

      if (formattedFromDate === formattedToDate) {
        searchElements.push(formattedFromDate);
      } else {
        searchElements.push(
          `${formattedFromDate} ${dateBetweenSeperator} ${formattedToDate}`
        );
      }
    }

    if (searchElements.length > 0 || (selectedFromDate && selectedToDate)) {
      // do filterQuery.pop() in backend to read and delete this from Query..
      // this is to read User's search keywords and use it to your own query
      filterQuery = filterQuery.concat({
        searchElements: searchElements,
      });
    }

    setFilterData(searchElements);
    props.handleSearch(filterQuery);
  };

  const resetFilter = () => {
    setFilterData([]);
    setSelectedDateRange(initialDateRange);
  };

  return (
    <Grid container>
      <Grid item xs>
        <EnhancedFilterBar
          disabled={props.disabled}
          getvalues={getValues}
          filterData={filterData}
          resetFilter={resetFilter}
          InputProps={{
            endAdornment: (
              <>
                {filterData.length ? (
                  <MyHoverIcons
                    muiIconHover={<CleaningServicesIcon />}
                    muiIcon={<ClearIcon />}
                    tooltip={"Clean"}
                    onClick={() => {
                      resetFilter();
                      getValues([]);
                    }}
                  />
                ) : null}
                <VoiceSearch
                  onVoiceSearch={(text) => {
                    getValues([...filterData, text]);
                  }}
                />
                <InputAdornment position="end">
                  <DateRangePickerModal
                    openDatePicker={openDatePicker}
                    setOpenDatePicker={setOpenDatePicker}
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                    onSubmit={handleDateSearch}
                  />
                </InputAdornment>
              </>
            ),
            style: { paddingRight: "15px" },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AdvanceSearchFilter;
